import * as React from "react";
import Testo from "../elementi/Testo";
import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";
import Alert from "@mui/material/Alert";


import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HttpsIcon from "@mui/icons-material/Https";
import { monFetch } from "../inc/inc";

export default function Comp(props) {
  const [password, setPassword] = useState("");
  const [confermaPassword, setConfermaPassword] = useState("");
  const [errore, setErrore] = useState(false);
  const [mexErrore, setMexErrore] = useState(null);
  const [mexOk, setMexOk] = useState(null);


  const [id, setId] = useState("");
  const [token, setToken] = useState("");

  async function reimpostaPassword(e, id) {
    e.preventDefault();
    // console.log(id, password, confermaPassword, token);
    const res = await monFetch("/password-reset", {
      password,
      password_confirm: confermaPassword,
      id: id,
      t: token,
    });
    // console.log(res);

    if (!res.success) {
      setErrore(true);
      setMexOk(false);
      setMexErrore(res.msg);
    } else if (res.success) {
      setErrore(false);
      setMexOk(res.msg);
      setTimeout(() => {
        window.location.replace("/login");
      }, 8000);
    }
  }

  useEffect(() => {
    setToken(new URLSearchParams(window.location.search).get("t"));
    setId(new URLSearchParams(window.location.search).get("id"));
  }, []);

  return (
    <div className="flexContCenter">
      <div className="boxlogin">
        <a href={"/login"}>
          <img src={"/img/logo-"+process.env.REACT_APP_AZIENDA_APP+".png"} />
        </a>
        <form onSubmit={(e) => reimpostaPassword(e, id)}>
          <Grid container spacing={2}>
            {!mexOk && (
              <>
                <Grid item xs={12}>
                  <h2>Imposta Password</h2>
                  <Testo
                    type="password"
                    label="Nuova Password"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    icona={<HttpsIcon />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Testo
                    type="password"
                    label="Conferma Password"
                    value={confermaPassword}
                    required
                    onChange={(e) => setConfermaPassword(e.target.value)}
                    icona={<HttpsIcon />}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              {errore && (
                <>
                  <br />
                  <Alert severity="error" sx={{ mb: 2 }}>
                    <span
                      dangerouslySetInnerHTML={{ __html: mexErrore }}
                    ></span>
                  </Alert>
                </>
              )}
              {mexOk && (
                <>
                  <br />
                  <Alert severity="success" sx={{ mb: 2 }}>
                    <span dangerouslySetInnerHTML={{ __html: mexOk }}></span>
                  </Alert>
                </>
              )}
              {!mexOk && (
                <Button variant="contained" type="submit" fullWidth>
                  Imposta Password
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <p style={{ textAlign: "center" }}>
                <a href={"/login"}>
                  <AccountCircleIcon
                    style={{ verticalAlign: "middle", marginRight: "5px" }}
                  />
                  <span style={{ verticalAlign: "middle" }}>
                    Torna al login
                  </span>
                </a>
              </p>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}
