import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";
import Alert from "@mui/material/Alert";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import LockIcon from "@mui/icons-material/Lock";
import { isAdminGlobale, monFetch } from "../inc/inc";

import Testo from "../elementi/Testo";

export default function Comp(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errore, setErrore] = useState("");
  const [code2fa, setCode2fa] = useState(null);
  const [testoCodice, setTestoCodice] = useState("");

  async function login(e) {
    e.preventDefault();
    const res = await monFetch("/login", {
      email,
      password,
      code2fa: code2fa,
    });
    // console.log(res);
    if (res.success) {
      if (res.insertCode) {
        ///se 2fa abilitato chiede il codice
        setCode2fa("");
      } else if (!res.is2faEnabled && res.admin_level == 3) {
        //se da abilitare il 2fa e utente Admin
        Cookies.set("token", res.token_2fa, { expires: 1 });
        Cookies.set("id", res.id, { expires: 1 });

        window.location.href =  "/abilita2fa";
      } else {
        //se utente mando SMS
        Cookies.set("token", res.token, { expires: 1 });
        Cookies.set("id", res.id, { expires: 1 });
        window.location.href =  "/";
      }
      Cookies.set("adminLevel", res.admin_level, { expires: 1 });
    } else {
      if (res.msgErrore) {
        setErrore(res.msgErrore);
      } else {
        setErrore("Credenziali errate");
      }

      setTimeout(() => {
        setErrore("");
      }, 5000);
    }

    if (res.testoCodice) {
      setTestoCodice(res.testoCodice);
    }
  }

  useEffect(() => {}, []);

  return (
    <div className="flexContCenter">
      <div className="boxlogin">
        <img src={ "/img/logo-"+process.env.REACT_APP_AZIENDA_APP+".png"} />

        <form onSubmit={(e) => login(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h2>Login</h2>
              {errore && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  {errore}
                </Alert>
              )}
              <Testo
                label="Email"
                type="email"
                value={email}
                icona={<AccountCircleIcon />}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Testo
                label="Password"
                type="password"
                value={password}
                icona={<LockIcon />}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            {code2fa !== null && (
              <Grid item xs={12}>
                <div style={{ marginBottom: 10, fontSize: 14 }}>
                  {testoCodice}
                </div>

                <Testo
                  label="Codice"
                  value={code2fa}
                  icona={<LockIcon />}
                  onChange={(e) => setCode2fa(e.target.value)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button variant="contained" fullWidth type="submit">
                Accedi
              </Button>
              <p style={{ textAlign: "center" }}>
                <a href={ "/recupera-password"}>
                  <AccountCircleIcon
                    style={{ verticalAlign: "middle", marginRight: "5px" }}
                  />
                  <span style={{ verticalAlign: "middle" }}>
                    Password Dimenticata
                  </span>
                </a>
              </p>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}
