import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Cookies from "js-cookie";
import { useLocation, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import { monFetch } from "../../inc/inc";
import MenuLaterale from "./MenuLaterale";

const Comp = (props) => {
  const [menuAperto, setMenuAperto] = useState(false);
  const [apriMenuUtente, setApriMenuUtente] = useState(false);
  const [emailUtente, setEmailUtente] = useState("");
  const [nomeCognome, setNomeCognome] = useState("");
  const [level, setLevel] = useState(null);

  let location = useLocation();

  useEffect(() => {
    getUtente();
  }, [location.pathname]);

  async function getUtente() {
    let res = await monFetch("/userGet", {
      id: Cookies.get("id"),
    });
    if (res.user) {
      setEmailUtente(res.user.email);
      setNomeCognome(res.user.first_name + " " + res.user.last_name);
      setLevel(res.user.level);
    }
  }

  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("id");
    Cookies.remove("adminLevel");
    window.location.href =  "/login";
  };

  return (
    <>
      <AppBar position="static" sx={{ mb: 2 }} className="barraTop">
        <Container style={{ paddingLeft: 0, paddingRight: 0 }} maxWidth={false}>
          <Toolbar style={{ justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <IconButton
                size="large"
                edge="start"
                sx={{ mr: 2 }}
                onClick={() => setMenuAperto(!menuAperto)}
              >
                <MenuIcon />
              </IconButton>

              <a
                href={ "/"}
                style={{ marginRight: "15px", display: "flex" }}
              >
                <img
                  src={ "/img/logo-"+process.env.REACT_APP_AZIENDA_APP+".png"}
                  style={{ width: "100px", padding: "10px 0" }}
                />
              </a>
            </div>
            <div className="titoloBarraTop">{props.testoTitolo}</div>
            <div style={{ display: "flex" }}>
              <Button color="inherit" onClick={logout}>
                Logout
              </Button>
              <IconButton
                className="bottoneUtente"
                onClick={() => setApriMenuUtente(!apriMenuUtente)}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                open={apriMenuUtente}
                anchorEl={document.querySelector(".bottoneUtente")}
                id="menu-appbar"
                onClick={() => setApriMenuUtente(!apriMenuUtente)}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() => setApriMenuUtente(!apriMenuUtente)}>
                  <div className="menuUtente">
                    {level}
                    <br />
                    <b>{nomeCognome}</b>
                    <br />
                    {emailUtente}
                    <br />
                    <a
                      href={ "/utente-modifica"}
                      className="linkButton"
                    >
                      Modifica Utente
                    </a>
                  </div>
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </Container>
      </AppBar>

      {/* menu laterale------- */}

      <MenuLaterale menuAperto={menuAperto} setMenuAperto={setMenuAperto} />

      <Container maxWidth={false} className="rootContainer">
        {/* //mostra la route figlia */}
        <Outlet />
      </Container>
      <br />
      <br />
      <br />
    </>
  );
};

export default Comp;
